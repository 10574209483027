.btn {
  cursor: pointer;
}
.btn--reset,
.btn--link {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: left;
}
a,
.btn--link {
  color: rgb(0, 0, 238);
  text-decoration: underline;
}
