.App {
  text-align: center;
}

h3 {
  margin: 0 0 1rem;
}
h3 > a {
  background: navy;
  color: yellow;
  text-decoration: none;
  padding: 0.5rem;
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
