.results-list {
  display: flex;
  list-style: none;
  width: 100%;
  overflow: auto;
  margin: 2rem 0;
  padding: 0;
}
.results-list__item {
  min-width: 150px; /* necessary for scroll restoration, should match poster image width */
}
.results-list__item > a {
  display: block;
  min-height: 225px; /* helps with scroll restoration, should match poster image height */
}
.results-list__item + .results-list__item {
  margin-left: 2rem;
}
